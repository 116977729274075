.clock-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 50%;
    left: 0;
    width: 250px;
    height: 250px;
    margin: auto;
    padding: 5px;
    /* background-color: #1ed62d; */
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .15), 0 2px 2px rgba(0, 0, 0, .2);

}

.clock-base {
    width: 250px;
    height: 250px;
    background-color: #e6e1e1;
    border-radius: 50%;
    /* box-shadow: 0 0 5px #eee; */
}

.click-indicator {
    position: absolute;
    z-index: 1;
    top: 15px;
    left: 15px;
    width: 230px;
    height: 230px;
}

.click-indicator div {
    position: absolute;
    width: 2px;
    height: 4px;
    margin: 113px 114px;
    background-color: black;
}

.click-indicator div:nth-child(1) {
    transform: rotate(30deg) translateY(-113px);
}

.click-indicator div:nth-child(2) {
    transform: rotate(60deg) translateY(-113px);
}

.click-indicator div:nth-child(3) {
    transform: rotate(90deg) translateY(-113px);
    background-color: red;
}

.click-indicator div:nth-child(4) {
    transform: rotate(120deg) translateY(-113px);
}

.click-indicator div:nth-child(5) {
    transform: rotate(150deg) translateY(-113px);
}

.click-indicator div:nth-child(6) {
    transform: rotate(180deg) translateY(-113px);
    background-color: red;
}

.click-indicator div:nth-child(7) {
    transform: rotate(210deg) translateY(-113px);
}

.click-indicator div:nth-child(8) {
    transform: rotate(240deg) translateY(-113px);
}

.click-indicator div:nth-child(9) {
    transform: rotate(270deg) translateY(-113px);
    background-color: red;
}

.click-indicator div:nth-child(10) {
    transform: rotate(300deg) translateY(-113px);
}

.click-indicator div:nth-child(11) {
    transform: rotate(330deg) translateY(-113px);
}

.click-indicator div:nth-child(12) {
    transform: rotate(360deg) translateY(-113px);
    background-color: red;
}

.clock-hour {
    position: absolute;
    z-index: 2;
    top: 80px;
    left: 128px;
    width: 4px;
    height: 65px;
    background-color: #555;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    transform-origin: 2px 50px;
    transition: .5s;
    animation: rotate-hour 43200s linear infinite;

}

.clock-minute {
    position: absolute;
    z-index: 3;
    top: 60px;
    left: 128px;
    width: 4px;
    height: 85px;
    background-color: #555;
    border-radius: 2px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    transform-origin: 2px 70px;
    transition: .5s;
    animation: rotate-minute 3600s linear infinite;

}

.clock-second {
    position: absolute;
    z-index: 4;
    top: 20px;
    left: 129px;
    width: 2px;
    height: 130px;
    background-color: red;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    transform-origin: 1px 110px;
    transition: .5s;
    animation: rotate-second 60s linear infinite;

}

.clock-second:after {
    content: "";
    display: block;
    position: absolute;
    left: -5px;
    bottom: 14px;
    width: 8px;
    height: 8px;
    background-color: #a00;
    border: solid 2px #a00;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.clock-center {
    position: absolute;
    z-index: 1;
    width: 150px;
    height: 150px;
    top: 55px;
    left: 55px;
    background-color: #59C3FF;
    border-radius: 50%;
    box-shadow: inset 0 -1px 0 #fafafa, inset 0 1px 0 #e3e3e3;
}

.clock-center:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin: 65px;
    background-color: #ddd;
    border-radius: 50%;
}