

 .cardchar {
     border-radius: 8px;
     border: 1px solid var(--818283, #818283);
     background: #FFF;
 }
 .cardchar:hover {
    border-radius: 8px;
    border: 1px solid #2196F3;
    background: #FFF;
    box-shadow: 2px 2px 6px 0px rgba(33, 150, 243, 0.10), -2px -2px 6px 0px rgba(33, 150, 243, 0.10);
}
.cardchar:active {
    border-radius: 8px;
    border: 1px solid #2196F3;
    background: #FFF;
    box-shadow: 2px 2px 6px 0px rgba(33, 150, 243, 0.10), -2px -2px 6px 0px rgba(33, 150, 243, 0.10);
}